import * as React from 'react';

import Seo from '../../components/seo';
import PersistError from '../../components/persistError';
import Loader from '../../components/loader';
import { useEffect, useState } from 'react';
import axios from 'axios';

const VerifyPage = ({ uuid }: any) => {
  const [stage, setStage] = useState<string>('initial');

  useEffect(() => {
    (async () => {
      try {
        await axios.post(`/api/verify`, { uuid });
        setStage('success');
      } catch (error) {
        setStage('error');
      }
    })();
  }, []);

  return (
    <div className="container mx-auto py-6 px-4">
      <Seo title="Home" description="Home" />
      {stage === 'loading' && <Loader text="Kleinen Augenblick..." />}
      {stage === 'error' && <PersistError />}
      {stage === 'success' && (
        <>
          <h2>Deine Email wurde verifiziert!</h2>
        </>
      )}
    </div>
  );
};

export default VerifyPage;
